import styled from 'styled-components'
import { StyledLink } from '../Link'
import { device } from '../../../utils/breakpoints'

export const Button = styled(StyledLink) `
    padding: 0.8rem 1.5rem;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    white-space: nowrap;
    background: ${({isGreen}) => isGreen ? '#2D6A4F' : '#fff'};  
    color: ${({isGreen}) => isGreen ? '#fff' : '#2D6A4F'};  
    border: 1px solid #2D6A4F;
    cursor: pointer;
    font-size: ${({theme}) => theme.fonts.size.xs};
    text-transform: none;
    text-align: center;

    @media ${device.tabletXL} {

        :hover {
            background: ${({isGreen}) => isGreen ? '#fff' : '#2D6A4F'};  
            color: ${({isGreen}) => isGreen ? '#2D6A4F' : '#fff'};
        }
    }
`
