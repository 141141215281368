import React, { useEffect } from 'react'
import PropTypes from "prop-types"
import styled from 'styled-components'

import { ThemeProvider } from 'styled-components'
import { GlobalStyles } from '../../utils/globalStyles'

import { AppProvider } from '../context'
import { theme } from '../../utils/theme'
import { Reset } from '../../utils/reset'
import { Cookie } from '../cookies'

import { gsap } from 'gsap'

import  { Header }  from '../header'
import { Footer } from '../footer'


const Main = styled.div `
    min-height: 100vh;
`


const Layout = ({ children, lodz, bydgoszcz, torun, choosePlace }) => {

    useEffect(() => { 
        gsap.to("body", 0, { css: { visibility: "visible" } });
    })

    if(bydgoszcz) {
        return (
            <ThemeProvider theme={theme}>
                <>
                    <AppProvider>
                    <GlobalStyles />
                    <Reset />
                    <Main>
                        <Header bydgoszcz/>
                        {children}
                        <Footer bydgoszcz/>
                    </Main>
                    </AppProvider>
                    <Cookie bydgoszcz/>
                </>
            </ThemeProvider>
        )
    } else if(lodz) {
        return (
            <ThemeProvider theme={theme}>
                <>
                    <AppProvider>
                    <GlobalStyles />
                    <Reset />
                    <Main>
                        <Header lodz/>
                        {children}
                        <Footer lodz/>
                    </Main>
                    </AppProvider>
                    <Cookie lodz/>
                </>
            </ThemeProvider>
        )
    } else if (torun) {
        return (
            <ThemeProvider theme={theme}>
                <>
                    <AppProvider>
                    <GlobalStyles />
                    <Reset />
                    <Main>
                        <Header torun />
                        {children}
                        <Footer torun/>
                    </Main>
                    </AppProvider>
                    <Cookie torun/>
                </>
            </ThemeProvider>
        )
    } else if (choosePlace) {
        return (
            <ThemeProvider theme={theme}>
                <>
                    <AppProvider>
                    <GlobalStyles />
                    <Reset />
                    <Main>
                        {children}
                    </Main>
                    </AppProvider>
                </>
            </ThemeProvider>
        )
    }
} 


Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default Layout