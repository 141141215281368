import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { StyledLink } from '../../common/link'
import { blinks, tlinks, llinks } from '../../common/data/links'
import { device } from '../../../utils/breakpoints'
import { FaInstagram, FaFacebook } from 'react-icons/fa'
import { RiArrowDropDownLine} from 'react-icons/ri'


const MobileNavContainer = styled.div `
    display: grid;
    gap: 2.5rem;
    grid-template-rows: auto;
    justify-items: center;
    align-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: ${({ theme }) => theme.colors.darkWhite};
    transform: translateX(${({ isOpen }) => (isOpen ? '0' : '-100%')});
    transition: all 0.3s ease;
    transition-property: opacity, transform;
    z-index: 3;

    @media ${device.laptop} {
        display: none;
    }
`
//Navigation

const NavLinks = styled.ul `
  display: flex;
  flex-direction: column;
  text-align: center;
`
const NavLinkContainer = styled.li `
  padding: 1rem 0;
`
const Link = styled(StyledLink) `
  color: ${({ theme }) => theme.colors.black};
  text-transform: uppercase;
`

const MobileNav = styled.nav `
    
`
const StyledButton = styled(StyledLink) `


`

// Social Media
const ContainerMobileNavSocialLinks = styled.div `
    justify-self: center;
`

const SocialIcons = styled.div `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100px;
    
`
const SocialLink = styled.a `
    color: ${({ theme }) => theme.colors.black};
    
`

const ContainerChoosePlace = styled.div `

    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 0.5rem;
    align-items: center;
    justify-content: center;

    span {
        font-size: 0.9rem;
        text-transform: uppercase;
    }

`

export const MobileNavigation = ({ isOpen, lodz, bydgoszcz, torun }) => {

    if (lodz) {
        return (
            <MobileNavContainer isOpen={isOpen}>
                <MobileNav>
                    <NavLinks>
                    {llinks.map((link) => {
                        const {id, url, text} = link;
                        return (
                            <NavLinkContainer key={id}>
                                <Link to={url}>{text}</Link>
                            </NavLinkContainer>
                        )
                    })}
                    </NavLinks>
                </MobileNav>
                <Link to='/'>
                <ContainerChoosePlace>
                        <RiArrowDropDownLine size={22} color="black"/><span>Wybierz lokalizację</span>
                </ContainerChoosePlace>
                </Link>
                <ContainerMobileNavSocialLinks>
                    <SocialIcons>
                        <SocialLink href="https://www.facebook.com/bodymedgrouplodz/">
                            <FaFacebook size={25} color="black" />
                        </SocialLink>
                        <SocialLink href="https://www.facebook.com/bodymedgrouplodz/">
                            <FaInstagram size={25} color="black"/>
                        </SocialLink>
                        
                    </SocialIcons>
                </ContainerMobileNavSocialLinks>
            </MobileNavContainer>
        )
        
    } else if(bydgoszcz) {
        return (
            <MobileNavContainer isOpen={isOpen}>
                <MobileNav>
                    <NavLinks>
                    {blinks.map((link) => {
                        const {id, url, text} = link;
                        return (
                            <NavLinkContainer key={id}>
                                <Link to={url}>{text}</Link>
                            </NavLinkContainer>
                        )
                    })}
                    </NavLinks>
                </MobileNav>
                <Link to='/'>
                    <ContainerChoosePlace>
                        <RiArrowDropDownLine size={22} color="black"/><span>Wybierz lokalizację</span>
                    </ContainerChoosePlace>
                </Link>
                <ContainerMobileNavSocialLinks>
                    <SocialIcons>
                        <SocialLink href="https://www.facebook.com/bodymedgrouplodz/">
                            <FaFacebook size={25} color="black" />
                        </SocialLink>
                        <SocialLink href="https://www.facebook.com/bodymedgrouplodz/">
                            <FaInstagram size={25} color="black"/>
                        </SocialLink>
                        
                    </SocialIcons>
                </ContainerMobileNavSocialLinks>
            </MobileNavContainer>
        )
        
    } else if(torun) {

        return (
            <MobileNavContainer isOpen={isOpen}>
                <MobileNav>
                    <NavLinks>
                    {tlinks.map((link) => {
                        const {id, url, text} = link;
                        return (
                            <NavLinkContainer key={id}>
                                <Link to={url}>{text}</Link>
                            </NavLinkContainer>
                        )
                    })}
                    </NavLinks>
                </MobileNav>
                <Link to='/'>
                    <ContainerChoosePlace>
                        <RiArrowDropDownLine size={22} color="black"/><span>Wybierz lokalizację</span>
                    </ContainerChoosePlace>
                </Link>
                <ContainerMobileNavSocialLinks>
                    <SocialIcons>
                        <SocialLink href="https://www.facebook.com/bodymedgrouplodz/">
                            <FaFacebook size={25} color="black" />
                        </SocialLink>
                        <SocialLink href="https://www.facebook.com/bodymedgrouplodz/">
                            <FaInstagram size={25} color="black"/>
                        </SocialLink>
                        
                    </SocialIcons>
                </ContainerMobileNavSocialLinks>
            </MobileNavContainer>
        )
        
    }
 
}

MobileNavigation.propTypes = { isOpen: PropTypes.bool }
MobileNavigation.defaultProps = { isOpen: false }