import { createGlobalStyle } from 'styled-components'




export const GlobalStyles = createGlobalStyle`


  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-family: Open Sans, sans-serif;
    font-display: auto;
  }

  body {
    font-size: 0.9rem;
    visibility: hidden;
    background: #F9F9F9;
  }

  *:focus {
    outline: none;
  }

  a {
    font-family: Open Sans, sans-serif;
  }

  input, textarea {
    font-family: Open Sans, sans-serif;
  }

  p {
    line-height: 1.5;
  }  
  
`