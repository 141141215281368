import React from 'react'
import { FaInstagram, FaFacebook } from 'react-icons/fa'

export const blinks = [
  {
    id: 1,
    url: '/bydgoszcz-zabiegi',
    text: 'zabiegi',
  },
  {
    id: 2,
    url: '/bydgoszcz-galeria',
    text: 'galeria',
  },
  {
    id: 3,
    url: '/bydgoszcz-cennik',
    text: 'cennik',
  },
  {
    id: 4,
    url: '/bydgoszcz-o-nas',
    text: 'o nas',
  },
  {
    id: 5,
    url: '/bydgoszcz-blog',
    text: 'blog',
  },
  {
    id: 6,
    url: '/bydgoszcz-kontakt',
    text: 'kontakt',
  },
  {
    id: 7,
    url: '/bydgoszcz-oferty-specjalne',
    text: 'oferty specjalne'
  },
]

export const bsocials = [
  {
    id: 1,
    url: 'https://www.facebook.com',
    icon: <FaFacebook  value={{ style: { size: '3rem' } }}/>,
  },
  {
    id: 2,
    url: 'https://www.instagram.com',
    icon: <FaInstagram />,
  },
]



export const llinks = [
  {
    id: 1,
    url: '/lodz-zabiegi',
    text: 'zabiegi',
  },
  {
    id: 2,
    url: '/lodz-galeria',
    text: 'galeria',
  },
  {
    id: 3,
    url: '/lodz-cennik',
    text: 'cennik',
  },
  {
    id: 4,
    url: '/lodz-o-nas',
    text: 'o nas',
  },
  {
    id: 5,
    url: '/lodz-blog',
    text: 'blog',
  },
  {
    id: 6,
    url: '/lodz-kontakt',
    text: 'kontakt',
  },
  {
    id: 7,
    url: '/lodz-oferty-specjalne',
    text: 'oferty specjalne',
  },
]

export const lsocials = [
  {
    id: 1,
    url: 'https://www.facebook.com',
    icon: <FaFacebook  value={{ style: { size: '3rem' } }}/>,
  },
  {
    id: 2,
    url: 'https://www.instagram.com',
    icon: <FaInstagram />,
  },
]


export const tlinks = [
  {
    id: 1,
    url: '/torun-zabiegi',
    text: 'zabiegi',
  },
  {
    id: 2,
    url: '/torun-galeria',
    text: 'galeria',
  },
  {
    id: 3,
    url: '/torun-cennik',
    text: 'cennik',
  },
  {
    id: 4,
    url: '/torun-o-nas',
    text: 'o nas',
  },
    {
    id: 5,
    url: '/torun-blog',
    text: 'blog',
  },
  {
    id: 6,
    url: '/torun-kontakt',
    text: 'kontakt',
  },
  {
    id: 7,
    url: '/torun-oferty-specjalne',
    text: 'oferty specjalne',
  },
]

export const tsocials = [
  {
    id: 1,
    url: 'https://www.facebook.com',
    icon: <FaFacebook  value={{ style: { size: '3rem' } }}/>,
  },
  {
    id: 2,
    url: 'https://www.instagram.com',
    icon: <FaInstagram />,
  },
]