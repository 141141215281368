import React, { useState} from 'react'
import styled from 'styled-components'

import { validate } from '../../../utils/validateNewsletter'
import { Checkbox } from '../checkbox/index'
import { device } from '../../../utils/breakpoints'

const StyledMessage = styled.span `
    color: white;
`

const ContainerForm = styled.div `
    display: grid;
    grid-template-rows: repeat(2, auto);
    gap: 1.5rem;
    z-index: 10;

`
const StyledForm = styled.form `
    display: grid;
    grid-template-rows: repeat(2, auto);
    gap: 0.1rem;

    @media ${device.mobileXL} {
        grid-template-columns: repeat(2, auto);
    }

`

const StyledLabel = styled.label `
    position: relative;
    width: 100%;

`

const StyledInput = styled.input `
    padding: 0.8rem 2rem;
    width: 17rem;
    border: none;
` 

const StyledButton = styled.button `
    padding: 0.8rem 2rem;
    width: 100%;
    border: none;
    color: ${({ theme }) => theme.colors.green};
    background: ${({ theme }) => theme.colors.white};
    cursor: pointer;

    @media ${device.tabletXL} {
        :hover {
            background: ${({isGreen}) => isGreen ? '#fff' : '#1B8640'};  
            color: ${({isGreen}) => isGreen ? '#1B8640' : '#fff'};
        }
    }
`

//Error


const Error = styled.p `
    position: absolute;
    left: 0px;
    bottom: -1.5rem;
    font-size: ${({theme}) => theme.fonts.size.xxs};
    color: white;
`

const ContainerCheckbox = styled.div `
    width: 100%;
    position: relative;
`


export const NewsletterForm = ({ black }) => {

    const [send, setSend] = useState(false)
    const [error, setError] = useState(null)
    const [form, setForm] = useState({
        email: '',
        checked: false,
    })

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
    }

    const handleChange = e => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setForm({
           ...form,
           [name]: value
        })
    }


    const handleSubmit = e => {
        e.preventDefault();

        const errorMsg = validate(form)

        if (errorMsg !== null) {
        setError(errorMsg)
        return
        }

        setError(null)

        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "newsletter", ...form })
        })
        .then(() =>  setSend(true))
        .then(setTimeout(() => { setSend(false) }, 3000))
        .then(() =>  setForm({
            email: '',
            checked: false,
        }))
        .catch(error => alert(error));
    
    }

    return (
        <>
        { send ? <StyledMessage>Dziękujemy za subskrypcję</StyledMessage> :
        <ContainerForm>
            <StyledForm
                onSubmit={handleSubmit} 
                name="newsletter" 
                method="post" 
                data-netlify="true" 
                data-netlify-honeypot="bot-field">
            <input type="hidden" name="form-name" value="newsletter" />
                <StyledLabel htmlFor='email'>
                
                    <StyledInput 
                        type="email" 
                        name='email'
                        placeholder='Podaj swój adres email.' 
                        aria-label='Email input'
                        onChange={handleChange} 
                        value={form.name}
                    />
                    
                </StyledLabel>
                <StyledButton type='submit' value='Send'>Zapisz się</StyledButton>
            </StyledForm>
          
                <StyledLabel>
                    <Checkbox lodz checked={form.checked} name='checked' onChange={handleChange} />
                    {error && <Error checkbox>{error.checked}</Error>}
                </StyledLabel>
          
        </ContainerForm>
        }
        </>
    )

}