import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'


const CheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 1rem;
  align-items: center;
  justify-content: start;

`
const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const Checkboxx = styled.div`
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #40916C;
  background: ${props => (props.checked ? '#40916C' : 'white')};
  transition: all 150ms;

  ${Icon} {
    visibility: ${props => (props.checked ? 'visible' : 'hidden')};
  }
`

const Paragraph = styled.p`
  font-size: ${({ theme }) => theme.fonts.size.xxs};

  color: ${props => (props.black ? '#40916C' : 'white')};
`

const StyledLink = styled(Link) `
  text-decoration: underline;
  color: ${props => (props.black ? '#40916C' : 'white')};
`


export const Checkbox = ({ bydgoszcz, torun, lodz, checked, black, ...props }) => {

  if (bydgoszcz) {
    return (
      <CheckboxContainer>
          <HiddenCheckbox checked={checked} {...props} />
          <Checkboxx checked={checked}>
              <Icon viewBox='0 0 24 24'>
                  <polyline points='20 6 9 17 4 12' />
              </Icon>
          </Checkboxx>
          <Paragraph black={black}>
            Akceptuję <StyledLink black={black} to='/bydgoszcz-polityka-prywatnosci'>politykę prywatności</StyledLink>
          </Paragraph>
      </CheckboxContainer>
    )
  } else if(lodz) {
    return (
      <CheckboxContainer>
          <HiddenCheckbox checked={checked} {...props} />
          <Checkboxx checked={checked}>
              <Icon viewBox='0 0 24 24'>
                  <polyline points='20 6 9 17 4 12' />
              </Icon>
          </Checkboxx>
          <Paragraph black={black}>
            Akceptuję <StyledLink black={black} to='/lodz-polityka-prywatnosci'>politykę prywatności</StyledLink>
          </Paragraph>
      </CheckboxContainer>
    )
  } else if(torun) {
    return (
      <CheckboxContainer>
          <HiddenCheckbox checked={checked} {...props} />
          <Checkboxx checked={checked}>
              <Icon viewBox='0 0 24 24'>
                  <polyline points='20 6 9 17 4 12' />
              </Icon>
          </Checkboxx>
          <Paragraph black={black}>
            Akceptuję <StyledLink black={black} to='/torun-polityka-prywatnosci'>politykę prywatności</StyledLink>
          </Paragraph>
      </CheckboxContainer>
    )
  }
}


Checkbox.propTypes = { checked: PropTypes.bool }

Checkbox.defaultProps = { checked: false }
