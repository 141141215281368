import React from 'react'

import styled from 'styled-components'

import { Wrapper } from '../common/wrapper'
import { Container } from '../common/container'
import { Heading } from '../common/heading'
import { NewsletterForm} from '../common/formNewsletter'

import { StaticImage } from "gatsby-plugin-image"

import { StyledLink } from '../common/link'
import { device  } from '../../utils/breakpoints'


//links
import { blinks, llinks, tlinks, tsocials, bsocials, lsocials } from '../common/data/links'


const StyledWrapper = styled(Wrapper) `
   background: ${({ theme }) => theme.colors.greenLight};
`


const StyledContainer = styled(Container) `
    grid-template-rows: auto;
`

const ContainerFooter = styled.div `
    display: grid;
    gap: 2rem;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: auto;

    @media ${device.tabletL} {
        grid-template-columns: repeat(3, auto);
        grid-template-rows: auto;

    }
`

//left

const ContaienrBusinessInformations = styled.div `
    display: grid;
    grid-template-rows: auto;
    gap: 2rem;
    align-content: start;
`

const StyledHeading = styled(Heading) `
    font-weight: 500;
    color: ${({ theme }) => theme.colors.white};

`

const BusinessInformations = styled.div `
    display: grid;
    grid-template-rows: auto;
    gap: 2rem;
`

const ContainerInformation = styled.div `
    display: grid;
    grid-template-columns: auto;
    gap: 0.8rem;
    color: ${({ theme }) => theme.colors.white};

    span {
        font-weight: 400;
        b {
            font-weight: 500;
        }
    }
`

const ContainerCards = styled.div `
    display: grid;
    grid-template-columns: auto auto;
    justify-content: start;
    gap: 2rem;
`



//center

const ContainerFooterMenu = styled.div `
    display: grid;
    grid-template-rows: auto;
    gap: 2rem;
    align-content: start;

`

const ContainerLinks = styled.ul `
    display: grid;
    grid-template-columns: auto;
    align-content: start;
    gap: 0.8rem;

    @media ${device.laptop} {
        grid-template-columns: repeat(2, auto);
    }

`

const ContainerLink = styled.li `

`

const NavStyledLink = styled(StyledLink) `
    text-transform: capitalize;
    font-weight: 400;
    white-space: nowrap;
    cursor: pointer;

`

//right

const ContainerNewsletterFooter = styled.div `
    display: grid;
    gap: 0.8rem;
    grid-template-rows: auto;
    justify-items: start;
    align-content: start;

    p {
        color: ${({ theme }) => theme.colors.white};
    }

    @media ${device.tabletL} { 
        justify-items: end;
    }

`

//bottom

const WrapperDownFooter = styled.div `
    display: grid;
    justify-items: center;
    padding: 0 5.5vw;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.green};
`
const ContainerDownFooter = styled.div `
    display: grid;
    padding: 1rem 0;
    width: 100%;
    align-items: center;
    gap: 2rem;
    grid-template-columns: auto;
    justify-content: space-between;    
    max-width: 1366px;

    .image-pfr {
        max-width: 600px;
    }

    span {
        font-size: ${({ theme }) => theme.fonts.size.xxs};
        color: ${({ theme }) => theme.colors.white};
    }

    @media ${device.tablet} { 
        grid-template-columns: auto auto;
    }
`

const StyledLinkA = styled.a `

`

export const Footer = ({ lodz, torun, bydgoszcz }) => {

    if(lodz) {
        return (
            <>
            <StyledWrapper>
                <StyledContainer>
                    <ContainerFooter>
    
                        <ContaienrBusinessInformations>
                            <StyledHeading h3 as='h3'>O Firmie</StyledHeading>
                            <BusinessInformations>
                                <ContainerInformation>
                                    <span><b>BodyMed Group</b></span>
                                    <span>Gdańska 149,</span>
                                    <span>90-536 Łódź</span>
                                </ContainerInformation>
    
                                <ContainerInformation>
                                <StyledLinkA href="mailto:lodz@bodymedgroup.pl">
                                <span>lodz@bodymedgroup.pl</span>
                            </StyledLinkA>
                            <StyledLinkA href="tel:+48697514234">
                                <span>+48 697 514 234</span>
                            </StyledLinkA>
                                </ContainerInformation>
    
                                <ContainerInformation>
                                    <span><b>Honorujemy karty:</b></span>
                                    <ContainerCards>
                                        <StaticImage
                                            src="../../images/multisport.png"
                                            alt="Logo BodyMed Group" 
                                            title="BodyMed Group" 
                                            layout="fixed"
                                            height={60}
                                        />
    
                                        <StaticImage
                                            src="../../images/fitprofit.png"
                                            alt="Logo BodyMed Group" 
                                            title="BodyMed Group" 
                                            layout="fixed"
                                            height={60}
                                        />
                                    </ContainerCards>
                                </ContainerInformation>
                            </BusinessInformations>
                        </ContaienrBusinessInformations>
    
                        <ContainerFooterMenu>
                            <StyledHeading h3 as='h3'>Menu</StyledHeading>
                            <ContainerLinks   >
                            {llinks.map((link) => {
                                const {id, url, text} = link
                                
                                return (
                                    <ContainerLink key={id}>
                                        <NavStyledLink 
                                            to={url}>
                                            {text}
                                        </NavStyledLink>
                                    </ContainerLink>
                                )
                            })}
                            </ContainerLinks>
                        </ContainerFooterMenu>
    
                        <ContainerNewsletterFooter> 
                            <StyledHeading h4 as='h4'>Pozostańmy w kontakcie!</StyledHeading>
                            <p>Zapisz się do naszego newslettera u bądź na bieżąco.</p>
                            <NewsletterForm/>
                        </ContainerNewsletterFooter>
                    </ContainerFooter>
                </StyledContainer>
               
            </StyledWrapper>
            <WrapperDownFooter>
                <ContainerDownFooter>
                    <span>2022 © BodyMed Group - Wszystkie prawa zastrzeżone</span>
                    <StaticImage
                        src="../../images/pfr-plansza.png"
                        alt="Logo BodyMed Group" 
                        title="BodyMed Group" 
                        layout="constrained"
                        className="image-pfr"
                    />
                
                </ContainerDownFooter>
            </WrapperDownFooter>
            </>
        )
    } else if(bydgoszcz) {
        return (
            <>
            <StyledWrapper>
                <StyledContainer>
                    <ContainerFooter>
    
                        <ContaienrBusinessInformations>
                            <StyledHeading h3 as='h3'>O Firmie</StyledHeading>
                            <BusinessInformations>
                                <ContainerInformation>
                                    <span><b>BodyMed Group</b></span>
                                    <span>M. Karłowicza 22</span>
                                    <span>85-092 Bydgoszcz</span>
                                </ContainerInformation>
    
                                <ContainerInformation>
                                <StyledLinkA href="mailto:bydgoszcz@bodymedgroup.pl">
                                <span>bydgoszcz@bodymedgroup.pl</span>
                            </StyledLinkA>
                            <StyledLinkA href="tel:+48506610131">
                                <span>+48 506 610 131</span>
                            </StyledLinkA>
                                </ContainerInformation>
    
                            </BusinessInformations>
                        </ContaienrBusinessInformations>
    
                        <ContainerFooterMenu>
                            <StyledHeading h3 as='h3'>Menu</StyledHeading>
                            <ContainerLinks   >
                            {blinks.map((link) => {
                                const {id, url, text} = link
                                
                                return (
                                    <ContainerLink key={id}>
                                        <NavStyledLink 
                                            to={url}>
                                            {text}
                                        </NavStyledLink>
                                    </ContainerLink>
                                )
                            })}
                            </ContainerLinks>
                        </ContainerFooterMenu>
    
                        <ContainerNewsletterFooter> 
                            <StyledHeading h4 as='h4'>Pozostańmy w kontakcie!</StyledHeading>
                            <p>Zapisz się do naszego newslettera u bądź na bieżąco.</p>
                            <NewsletterForm/>
                        </ContainerNewsletterFooter>
                    </ContainerFooter>
                </StyledContainer>
               
            </StyledWrapper>
            <WrapperDownFooter>
                <ContainerDownFooter>
                    <span>2022 © BodyMed Group - Wszystkie prawa zastrzeżone</span>
                    <StaticImage
                        src="../../images/pfr-plansza.png"
                        alt="Logo BodyMed Group" 
                        title="BodyMed Group" 
                        layout="constrained"
                        className="image-pfr"
                    />
                
                </ContainerDownFooter>
            </WrapperDownFooter>
            </>
        )
    } else if(torun) {
        return (
            <>
            <StyledWrapper>
                <StyledContainer>
                    <ContainerFooter>
    
                        <ContaienrBusinessInformations>
                            <StyledHeading h3 as='h3'>O Firmie</StyledHeading>
                            <BusinessInformations>
                                <ContainerInformation>
                                    <span><b>BodyMed Group</b></span>
                                    <span>Lubicka 37</span>
                                    <span>87-100 Toruń</span>
                                </ContainerInformation>
    
                                <ContainerInformation>
                                <StyledLinkA href="mailto:torun@bodymedgroup.pl">
                                <span>torun@bodymedgroup.pl</span>
                            </StyledLinkA>
                            <StyledLinkA href="tel:+48512115113">
                                <span>+48 512 115 113</span>
                            </StyledLinkA>
                                </ContainerInformation>

                            </BusinessInformations>
                        </ContaienrBusinessInformations>
    
                        <ContainerFooterMenu>
                            <StyledHeading h3 as='h3'>Menu</StyledHeading>
                            <ContainerLinks   >
                            {tlinks.map((link) => {
                                const {id, url, text} = link
                                
                                return (
                                    <ContainerLink key={id}>
                                        <NavStyledLink 
                                            to={url}>
                                            {text}
                                        </NavStyledLink>
                                    </ContainerLink>
                                )
                            })}
                            </ContainerLinks>
                        </ContainerFooterMenu>
    
                        <ContainerNewsletterFooter> 
                            <StyledHeading h4 as='h4'>Pozostańmy w kontakcie!</StyledHeading>
                            <p>Zapisz się do naszego newslettera u bądź na bieżąco.</p>
                            <NewsletterForm/>
                        </ContainerNewsletterFooter>
                    </ContainerFooter>
                </StyledContainer>
               
            </StyledWrapper>
            <WrapperDownFooter>
                <ContainerDownFooter>
                    <span>2022 © BodyMed Group - Wszystkie prawa zastrzeżone</span>
                    <StaticImage
                        src="../../images/pfr-plansza.png"
                        alt="Logo BodyMed Group" 
                        title="BodyMed Group" 
                        layout="constrained"
                        className="image-pfr"
                    />
                
                </ContainerDownFooter>
            </WrapperDownFooter>
            </>
        )
    }

    
}