/* eslint-disable no-useless-escape */
export const validate = form => {
    const { email, checked } = form
    const error = {}
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  
    if (!email) error.email = 'Proszę podać email'
    else if (!re.test(email)) error.email = 'Niepoprawny adres email'
  
  
    if (!checked) error.checked = 'Prosze zaakceptować politykę prywatności'
  
    if (Object.entries(error).length === 0 && error.constructor === Object) return null
  
    return error
  }
  