import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
const AppContext = React.createContext();

const AppProvider = ({ children }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMobileMenu = () => {
        setIsMenuOpen(!isMenuOpen)
    }

    return (
    <AppContext.Provider
        value={{
            isMenuOpen,
            toggleMobileMenu,
        }}
    >
      {children}
    </AppContext.Provider>
    )
}

export const useGlobalContext = () => {
    return useContext(AppContext);
};
  
export { AppContext, AppProvider };

AppProvider.propTypes = {
    children: PropTypes.node.isRequired
}