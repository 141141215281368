import React from 'react'
import CookieConsent from 'react-cookie-consent'
import { Link } from 'gatsby'
import styled from 'styled-components'

const LinkCookie = styled(Link) `
  color: #151515;
  cursor: pointer;
  text-decoration: underline;
`

export const Cookie = ({ lodz, torun, bydgoszcz }) => {

    if (bydgoszcz) {
        return  (
            <CookieConsent
                location='bottom'
                buttonText='Akceptuję'
                cookieName='BodyMedGroup'
                style={{
                    zIndex: '1000',
                    background: '#f7f9fb',
                    color: '#151515',
                    alignItems: 'center',
                    padding: '1.3rem 2rem',
                    flexWrap: 'nowrap',
                }}
                buttonStyle={{
                    color: '#fff',
                    cursor: 'pointer',
                    background: '#2D6A4F',
                    margin: '0',
                    fontSize: '15px',
                    fontFamily: 'Open Sans',
                    padding: '0.5rem 1.1rem',
                    borderRadius: '3px',
             
                }}
                contentStyle={{
                    flex: 'unset',
                    margin: '0 1rem 0 0',
                    fontSize: '15px',
                    fontFamily: 'Open Sans', 
                }}
                expires={150}
            >
                W trosce o bezpieczeństwo i wysoki standard obsługi, wykorzystujemy pliki <LinkCookie to='/bydgoszcz-polityka-prywatnosci'>cookies.</LinkCookie>
            </CookieConsent>
    )
    } if (lodz) {
        return  (
            <CookieConsent
                location='bottom'
                buttonText='Akceptuję'
                cookieName='BodyMedGroup'
                style={{
                zIndex: '1000',
                background: '#f7f9fb',
                color: '#151515',
                alignItems: 'center',
                padding: '1.3rem 2rem',
                flexWrap: 'nowrap',
                }}
                buttonStyle={{
                    color: '#fff',
                    cursor: 'pointer',
                    background: '#2D6A4F',
                    margin: '0',
                    fontSize: '15px',
                    fontFamily: 'Open Sans',
                    padding: '0.5rem 1.1rem',
                    borderRadius: '3px',
                }}
                contentStyle={{
                    flex: 'unset',
                    margin: '0 1rem 0 0',
                    fontSize: '15px',
                    fontFamily: 'Open Sans', 
                }}
                expires={150}
            >
                W trosce o bezpieczeństwo i wysoki standard obsługi, wykorzystujemy pliki <LinkCookie to='/lodz-cookies'>cookies.</LinkCookie>
            </CookieConsent>
    )
    } else if (torun) {
        return  (
            <CookieConsent
                location='bottom'
                buttonText='Akceptuję'
                cookieName='BodyMedGroup'
                style={{
                zIndex: '1000',
                background: '#f7f9fb',
                color: '#151515',
                alignItems: 'center',
                padding: '1.3rem 2rem',
                flexWrap: 'nowrap',
                }}
                buttonStyle={{
                    color: '#fff',
                    cursor: 'pointer',
                    background: '#2D6A4F',
                    margin: '0',
                    fontSize: '15px',
                    fontFamily: 'Open Sans',
                    padding: '0.5rem 1.1rem',
                    borderRadius: '3px',
                }}
                contentStyle={{
                    flex: 'unset',
                    margin: '0 1rem 0 0',
                    fontSize: '15px',
                    fontFamily: 'Open Sans', 
                }}
                expires={150}
            >
                W trosce o bezpieczeństwo i wysoki standard obsługi, wykorzystujemy pliki <LinkCookie to='/torun-cookies'>cookies.</LinkCookie>
            </CookieConsent>
    )
    } 
   }
