import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"

import { Hamburger } from './hamburger'
import { MobileNavigation } from './mobile'
import { useGlobalContext } from '../context'
import { Button } from '../common/button'

import { StyledLink } from '../common/link'

import { Container } from '../common/container'
import { Wrapper } from '../common/wrapper'

import { Link } from 'gatsby'


import { FaInstagram, FaFacebook, FaEnvelope, FaPhoneAlt } from 'react-icons/fa'
import { RiArrowDropDownLine} from 'react-icons/ri'
import {  ImLocation } from 'react-icons/im'
import { device  } from '../../utils/breakpoints'
import { blinks, llinks, tlinks, tsocials, bsocials, lsocials } from '../common/data/links'


//SubHeader
const StyledWrapperSubHeader = styled(Wrapper) `
    
    display: none;
    @media ${device.laptop} { 
        display: grid;
        background: ${({ theme }) => theme.colors.green};
        height: 3rem;
        z-index: 1000;
    }
`

const StyledContainerSubHeader = styled.div`
    display: grid;
    align-items: center;
    justify-content: space-between;
    display: grid;
    grid-template-columns: auto;
    width: 100%;
    height: 100%;
    max-width: 1366px;


`


const SubHeaderContactInformation = styled.div `
    grid-column: 1;
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: 1.5rem;
    align-content: start;
`



const SubHeaderStyledLink = styled.a `
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 0.5rem;
    align-items: center;
    justify-content: center;

    span {
        font-size: 0.9rem;
    }
`

const SubHeaderStyled = styled.div `
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 0.5rem;
    align-items: center;
    justify-content: center;

    span {
        font-size: 0.9rem;
        color: white;
    }
`

const SubHeaderStyledLinkLocalizaction = styled(StyledLink) `
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 0.5rem;
    align-items: center;
    justify-content: center;

    span {
        font-size: 0.9rem;
    }
`

const SubHeaderSocialMedia = styled.div `
    grid-column: 2;
    display: grid; 
    grid-template-columns: repeat(2, auto);
    gap: 1rem;
    justify-content: end;

`

//Nav

const HeaderWrapper = styled(Wrapper) `
    background: ${({ theme }) => theme.colors.white};
    z-index: 1000;
    position: sticky;
    height: 5rem;
    display: grid;
    top: 0;

    ${({ navbar }) =>
      navbar &&
      css`
        box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px;
      `
    }

`

const HeaderContainer = styled.div `
    max-width: 1366px;
    width: 100%;
    height: 100%;
`
const Navigation = styled.nav `
    display: grid;
    align-items: center;
    grid-template-columns: auto auto;
    justify-content: space-between;
    width: 100%;
    height: 100%;


`

const LogoContainer = styled.div `
    grid-column: 1;
`

const ContainerLinks = styled.ul `
    display: none;
    
    @media ${device.laptop} {
        display: grid; 
        grid-template-columns: repeat(8, auto);
        max-width: 800px;
        gap: 2rem;
        align-items: center;
        justify-self: end;


       
    }

   

  
   

`

const ContainerLink = styled.li `

`   

const NavStyledLink = styled(StyledLink) `
    text-transform: capitalize;
    white-space: nowrap;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.black};
    font-weight: 500;
    font-size: ${({ theme }) => theme.fonts.size.xs};

`

const StyledButton = styled(Button) `

`

export const Header = ({ bydgoszcz, lodz, torun }) => {

    const [navbar, setNavbar] = useState(false)
  
  
    const changeBackground = () => {
      if(window.scrollY >= 50) {
          setNavbar(true)
      } else {
          setNavbar(false)
      }
    }

    React.useEffect(() => {
        window.addEventListener('scroll', changeBackground)
      })
    
    const { isMenuOpen, toggleMobileMenu, isPlace, setIsPlace} = useGlobalContext();

    typeof window !== 'undefined' &&
    window.addEventListener('resize', () => {
      window.innerWidth >= 1024 && isMenuOpen && toggleMobileMenu()
    })

    if(bydgoszcz) { 
        return (

            <>
                <StyledWrapperSubHeader>
                    <StyledContainerSubHeader>
                        <SubHeaderContactInformation>
                                <SubHeaderStyledLink href="tel:+48506610131">
                                    <FaPhoneAlt size={18} color="white" /><span>+48 506 610 131</span> 
                                </SubHeaderStyledLink>
                                <SubHeaderStyledLink href="mailto:bydgoszcz@bodymedgroup.pl">
                                    <FaEnvelope size={18} color="white" /><span>bydgoszcz@bodymedgroup.pl</span> 
                                </SubHeaderStyledLink>
                                <SubHeaderStyled>
                                    <ImLocation size={18} color="white" /><span>M. Karłowicza 22, 85-092 Bydgoszcz</span> 
                                </SubHeaderStyled>
                                <SubHeaderStyledLinkLocalizaction to='/'>
                                    <RiArrowDropDownLine size={22} color="white"/><span>Wybierz lokalizację</span>
                                </SubHeaderStyledLinkLocalizaction>
                        </SubHeaderContactInformation>
                        <SubHeaderSocialMedia>
                            <SubHeaderStyledLink href="https://www.facebook.com/bodymedgroup">
                                <FaFacebook size={18} color="white"/>
                            </SubHeaderStyledLink>
                            <SubHeaderStyledLink href="https://www.instagram.com/bodymedgroup">
                                <FaInstagram size={18} color="white" />
                            </SubHeaderStyledLink>
                        </SubHeaderSocialMedia>
                    </StyledContainerSubHeader>
                </StyledWrapperSubHeader>     
                <HeaderWrapper navbar={navbar}>
                    <HeaderContainer>
                        <Navigation>
                            <NavStyledLink to='/bydgoszcz'>
                                <LogoContainer>
                                    <StaticImage
                                        src="../../images/logo.png"
                                        alt="Logo BodyMed Group" 
                                        title="BodyMed Group" 
                                        layout="fixed"
                                        height={60}
                                    />
                                </LogoContainer>    
                            </NavStyledLink>
                            
                            <ContainerLinks>    
                                {blinks.map((link) => {
                                    const {id, url, text} = link
                                    
                                    return (
                                        <ContainerLink key={id}>
                                            <NavStyledLink 
                                                activeStyle={{ color: "#2D6A4F" }} 
                                                partiallyActive={true}
                                                to={url}>
                                                {text}
                                            </NavStyledLink>
                                        </ContainerLink>
                                    )
                                })}
                                 <StyledButton to='https://www.moment.pl/bodymedgroup-bydgoszcz'>Rezerwuj Online</StyledButton>
                            </ContainerLinks>
                            <Hamburger isOpen={isMenuOpen} onClick={toggleMobileMenu} />
                            <MobileNavigation isOpen={isMenuOpen} bydgoszcz />
                        </Navigation>
                    </HeaderContainer>
                </HeaderWrapper>
            </>
        
        )

    }  else if(lodz) { 
        return (

            <>
                <StyledWrapperSubHeader>
                    <StyledContainerSubHeader>
                        <SubHeaderContactInformation>
                                <SubHeaderStyledLink href="tel:+48697514234">
                                    <FaPhoneAlt size={18} color="white" /><span>697 514 234</span> 
                                </SubHeaderStyledLink>
                                <SubHeaderStyledLink href="mailto:lodz@bodymedgroup.pl">
                                    <FaEnvelope size={18} color="white" /><span>lodz@bodymedgroup.pl</span> 
                                </SubHeaderStyledLink>
                                <SubHeaderStyled>
                                    <ImLocation size={18} color="white" /><span>Gdańska 149, 90-536 Łódź</span> 
                                </SubHeaderStyled>
                                <SubHeaderStyledLinkLocalizaction to='/'>
                                    <RiArrowDropDownLine size={22} color="white"/><span>Wybierz lokalizację</span>
                                </SubHeaderStyledLinkLocalizaction>
                        </SubHeaderContactInformation>
                        <SubHeaderSocialMedia>
                            <SubHeaderStyledLink href="https://www.facebook.com/bodymedgroup">
                                <FaFacebook size={18} color="white"/>
                            </SubHeaderStyledLink>
                            <SubHeaderStyledLink href="https://www.instagram.com/bodymedgroup">
                                <FaInstagram size={18} color="white" />
                            </SubHeaderStyledLink>
                        </SubHeaderSocialMedia>
                    </StyledContainerSubHeader>
                </StyledWrapperSubHeader>     
                <HeaderWrapper navbar={navbar}>
                    <HeaderContainer>
                        <Navigation>
                            <NavStyledLink to='/lodz'>
                                <LogoContainer>
                                    <StaticImage
                                        src="../../images/logo.png"
                                        alt="Logo BodyMed Group" 
                                        title="BodyMed Group" 
                                        layout="fixed"
                                        height={60}
                                    />
                                </LogoContainer>    
                            </NavStyledLink>
                            <ContainerLinks>    
                                {llinks.map((link) => {
                                    const {id, url, text} = link
                                    
                                    return (
                                        <ContainerLink key={id}>
                                            <NavStyledLink 
                                                activeStyle={{ color: "#2D6A4F" }} 
                                                partiallyActive={true} 
                                                to={url}>
                                                    {text}
                                            </NavStyledLink>
                                        </ContainerLink>
                                    )
                                })}
                                 <StyledButton to='https://www.moment.pl/bodymedgroup-lodz'>Rezerwuj Online</StyledButton>
                            </ContainerLinks>
                            
                            <Hamburger isOpen={isMenuOpen} onClick={toggleMobileMenu} />
                            <MobileNavigation isOpen={isMenuOpen} lodz />
                        </Navigation>
                    </HeaderContainer>
                </HeaderWrapper>
            </>
        
        )
    } else if(torun) {

        return (

            <>
                <StyledWrapperSubHeader>
                    <StyledContainerSubHeader>
                        <SubHeaderContactInformation>
                                <SubHeaderStyledLink href="tel:+48512115113">
                                    <FaPhoneAlt size={18} color="white" /><span>512 115 113</span> 
                                </SubHeaderStyledLink>
                                <SubHeaderStyledLink href="mailto:torun@bodymedgroup.pl">
                                    <FaEnvelope size={18} color="white" /><span>torun@bodymedgroup.pl</span> 
                                </SubHeaderStyledLink>
                                <SubHeaderStyled>
                                    <ImLocation size={18} color="white" /><span>Lubicka 37, 87-100 Toruń</span> 
                                </SubHeaderStyled>
                                <SubHeaderStyledLinkLocalizaction to='/'>
                                    <RiArrowDropDownLine size={22} color="white"/><span>Wybierz lokalizację</span>
                                </SubHeaderStyledLinkLocalizaction>
                        </SubHeaderContactInformation>
                        <SubHeaderSocialMedia>
                            <SubHeaderStyledLink href="https://www.facebook.com/bodymedgroup">
                                <FaFacebook size={18} color="white"/>
                            </SubHeaderStyledLink>
                            <SubHeaderStyledLink href="https://www.instagram.com/bodymedgroup">
                                <FaInstagram size={18} color="white" />
                            </SubHeaderStyledLink>
                        </SubHeaderSocialMedia>
                    </StyledContainerSubHeader>
                </StyledWrapperSubHeader>     
                <HeaderWrapper navbar={navbar}>
                    <HeaderContainer>
                        <Navigation>
                            <NavStyledLink to='/torun'>
                                <LogoContainer>
                                    <StaticImage
                                        src="../../images/logo.png"
                                        alt="Logo BodyMed Group" 
                                        title="BodyMed Group" 
                                        layout="fixed"
                                        height={60}
                                    />
                                </LogoContainer>    
                            </NavStyledLink>
                            <ContainerLinks>    
                                {tlinks.map((link) => {
                                    const {id, url, text} = link
                                    
                                    return (
                                        <ContainerLink key={id}>
                                            <NavStyledLink 
                                                activeStyle={{ color: "#2D6A4F" }}  
                                                partiallyActive={true}
                                                to={url}>
                                                    {text}
                                            </NavStyledLink>
                                        </ContainerLink>
                                    )
                                })}
                                <StyledButton to='https://www.moment.pl/bodymedgroup-torun'>Rezerwuj Online</StyledButton>
                            
                            </ContainerLinks>
                            <Hamburger isOpen={isMenuOpen} onClick={toggleMobileMenu} />
                            <MobileNavigation isOpen={isMenuOpen} torun />
                        </Navigation>
                    </HeaderContainer>
                </HeaderWrapper>
            </>
        
        )
    }

   
}