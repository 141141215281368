import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { device  } from '../../../utils/breakpoints'


const Container = styled.div`
    justify-self: end;
    grid-column: 3 / 4;

    @media ${device.laptop} {
        display: none;
    }
    overflow: hidden;
    width: calc(1.6rem + 1rem);
    height: calc(1.4rem + 1rem);
    position: relative;
    right: -0.5rem;
    cursor: pointer;
    padding: 0.5rem;
    z-index: 10;
    margin-left: -30px;
`

const Line = styled.span`
    transform-origin: center;
    position: absolute;
    width: calc(100% - 1rem);
    height: 0.1rem;
    background: ${({ theme }) => theme.colors.black};
    transition: all 0.3s ease; 

:nth-child(1) {
  top: 0.7rem;
  transform: ${({ isOpen }) => (isOpen ? 'translateY(7px) rotate(-45deg)' : 'translateY(0) rotate(0)')};
}

:nth-child(2) {
  top: 50%;
  opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
  transform: ${({ isOpen }) => (isOpen ? 'translate(calc(100% + 0rem), -50%)' : 'translate(0%, -50%)')};
}

:nth-child(3) {
  bottom: 0.7rem;
  transform: ${({ isOpen }) => (isOpen ? 'translateY(-7px) rotate(45deg)' : 'translateY(0) rotate(0)')};
}

`


export const Hamburger = props => {
    const { isOpen } = props
  
  return (
    <Container {...props }>
      <Line isOpen={isOpen}/>
      <Line isOpen={isOpen}/>
      <Line isOpen={isOpen} />
    </Container>
  )
}

Hamburger.propTypes = { isOpen: PropTypes.bool }
Hamburger.defaultProps = { isOpen: false }