
export const theme = {
    fonts: {
      size: {
        xxxs: '.7rem',
        xxs: '.8rem',
        xs: '.9rem',
        s: '1rem',
        m: '1.2rem',
        l: '1.5rem',
        xl: '1.7rem',
        xxl: '2.4rem',
        xxxl: '3.2rem',
        xxxxl: '4.5rem',
      }
    },
    
    colors: {
      white: '#fff',
      darkWhite: '#F9F9F9',
      black: '#151515',
      green: '#2D6A4F',
      greenLight: '#40916C',
    },
  }
  